import Pather from "services/Pather.service";

export class ApiRoutes {
  // ADMIN
  appAdmin = new Pather("admin");
  appAdminOverview = new Pather("overview", this.appAdmin);

  // APP - provides various data for the users, and a route for admins to work on the app
  app = new Pather("app");
  appUpdate = new Pather("update", this.app);
  appSettings = new Pather("settings", this.app);
  appSettingsReset = new Pather("reset", this.appSettings);
  appSettingsMailTest = new Pather("/mail/test", this.appSettings);

  // NOTIFICATIONS
  notifications = new Pather("notifications");
  notificationsClear = new Pather("/clear", this.notifications);
  notification = new Pather("/:notificationId", this.notifications);
  notificationRead = new Pather("/read", this.notification);

  // DASHBOARD
  dashboard = new Pather("dashboard");

  // ANALYTICS
  analytics = new Pather("analytics");

  // AGENT KEYS
  agentKeys = new Pather("agent-keys");
  agentKey = new Pather("/:keyId", this.agentKeys);
  agentKeysCheck = new Pather("/check", this.agentKeys);

  // API KEYS
  apiKeys = new Pather("api-keys");
  apiKey = new Pather("/:id", this.apiKeys);
  apiKeysCheck = new Pather("/check", this.apiKeys);

  // AGENT JOBS
  agentJobs = new Pather("agent-jobs");

  // DOMAINS
  domains = new Pather("domains");
  domain = new Pather("/:domainId", this.domains);
  domainCheck = new Pather("/check", this.domains);

  // ENVIRONMENTS
  environments = new Pather("environments");
  environment = new Pather("/:environmentId", this.environments);
  environmentCheck = new Pather("/check", this.environments);

  // ENDPOINTS
  endpoints = new Pather("endpoints");
  endpoint = new Pather("/:endpointId", this.endpoints);
  endpointAdmins = new Pather("/admins", this.endpoint);
  endpointAdmin = new Pather("/:adminId", this.endpointAdmins);
  endpointStig = new Pather("/stig", this.endpoint);
  endpointScap = new Pather("/scap", this.endpoint);
  endpointAcas = new Pather("/acas", this.endpoint);
  endpointArchive = new Pather("/archive", this.endpoint);
  endpointCheck = new Pather("/check", this.endpoints);
  endpointTeam = new Pather("/team", this.endpoint);

  // NETWORK
  networks = new Pather("network-addresses");
  network = new Pather("/:networkAddressId", this.networks);

  // GROUPS
  groups = new Pather("groups");
  group = new Pather("/:groupId", this.groups);
  groupCheck = new Pather("/check", this.groups);
  groupEndpoints = new Pather("/endpoints", this.group);
  groupEndpoint = new Pather("/:endpointId", this.groupEndpoints);
  groupStigBenchmarks = new Pather("/stig-benchmarks", this.group);
  groupStigBenchmark = new Pather("/:benchmarkId", this.groupStigBenchmarks);

  // DOCUMENTS
  documents = new Pather("documents");
  documentCheck = new Pather("/check", this.documents);
  documentTemplates = new Pather("/templates", this.documents);
  documentTemplate = new Pather("/:name", this.documentTemplates);
  document = new Pather("/:documentId", this.documents);
  documentLocation = new Pather("/location", this.document);
  documentDraft = new Pather("/draft", this.document);
  documentVersions = new Pather("/versions", this.document);
  documentVersion = new Pather("/:versionId", this.documentVersions);
  documentTags = new Pather("/tags", this.document);
  documentTag = new Pather("/:tagId", this.documentTags);

  // DOCUMENT FOLDERS
  documentFolders = new Pather("document-folders");
  documentFoldersCheck = new Pather("/check", this.documentFolders);
  documentFolder = new Pather("/:folderId", this.documentFolders);
  documentFolderDownload = new Pather("/download", this.documentFolder);

  // TEAMS
  teams = new Pather("teams");
  team = new Pather("/:teamId", this.teams);
  teamEndpoints = new Pather("/endpoints", this.team);
  teamCheck = new Pather("/check", this.teams);
  teamMembers = new Pather("members", this.team);
  teamMember = new Pather("/:memberId", this.teamMembers);

  // USERS
  users = new Pather("users");
  user = new Pather("/:userId", this.users);
  userCheck = new Pather("/check", this.users);

  // TAGS
  tags = new Pather("tags");

  // TYPES
  typesAll = new Pather("types");
  types = new Pather("types/:name");
  type = new Pather("/:typeId", this.types);

  // COMPLIANCE

  remediations = new Pather("remediation");
  remediation = new Pather("/:incidentId", this.remediations);
  remediationStatusRequest = new Pather("/status-request", this.remediation);
  remediationResolve = new Pather("/resolve", this.remediation);
  remediationScans = new Pather("/scans", this.remediation);
  remediationScan = new Pather("/:scanId", this.remediationScans);
  remediationWorkLogs = new Pather("/work-logs", this.remediation);
  remediationWorkLog = new Pather("/:logId", this.remediationWorkLogs);
  remediationItem = new Pather("/:itemId", this.remediation);

  complianceDocuments = new Pather("documentation");
  complianceDocumentsCheck = new Pather("/check", this.complianceDocuments);
  complianceDocument = new Pather("/:documentId", this.complianceDocuments);
  complianceDocumentTargets = new Pather("/targets", this.complianceDocument);
  complianceDocumentAssignedDocument = new Pather(
    "/document",
    this.complianceDocument
  );
  complianceDocumentSupportingDocuments = new Pather(
    "/supporting-documents",
    this.complianceDocument
  );
  complianceDocumentSupportingDocument = new Pather(
    "/:supportingDocumentId",
    this.complianceDocumentSupportingDocuments
  );

  /**
   *                       AUDIT
   */
  ccriAudits = new Pather("audits/ccri");
  ccriAudit = new Pather("/:version", this.ccriAudits);

  /**
   *                       STIG
   */
  // Benchmark
  stigBenchmarks = new Pather("stig-benchmarks");
  stigBenchmark = new Pather("/:benchmarkId", this.stigBenchmarks);
  stigBenchmarkEndpoints = new Pather("/endpoints", this.stigBenchmark);
  // Rules
  stigRules = new Pather("stig-rules");
  stigRule = new Pather("/:id", this.stigRules);
  // Comments
  stigComments = new Pather("/stig-comments");
  stigComment = new Pather("/:id", this.stigComments);
  // Checklists
  stigChecklists = new Pather("stig-checklists");
  stigChecklist = new Pather("/:checklistId", this.stigChecklists);
  stigChecklistImportScan = new Pather("/import-scan", this.stigChecklist);
  stigChecklistCreateAndImportChecklist = new Pather(
    "/create-import-checklist",
    this.stigChecklists
  );
  stigChecklistImportChecklist = new Pather(
    "/import-checklist",
    this.stigChecklist
  );
  stigChecklistReset = new Pather("/reset", this.stigChecklist);
  stigChecklistDownload = new Pather("/download", this.stigChecklist);
  stigChecklistscan = new Pather("/scan", this.stigChecklist);
  stigChecklistExport = new Pather("/export", this.stigChecklist);
  stigChecklistResults = new Pather("/results", this.stigChecklist);
  stigChecklistResult = new Pather("/:resultId", this.stigChecklistResults);
  // STIG Scan Policy
  stigScanPolicies = new Pather("stig-scan-policy");
  stigScanPolicy = new Pather("/:policyUuid", this.stigScanPolicies);
  // stigScanPolicyRun = new Pather('/run', this.stigScanPolicy);
  stigScanPolicyfolders = new Pather("/folders", this.stigScanPolicies);
  stigScanPolicyfolder = new Pather("/:folderName", this.stigScanPolicyfolders);
  // STIG Scans
  stigScans = new Pather("stig-scans");
  stigScan = new Pather("/:scanId", this.stigScans);
  // STIG Scan Reports
  stigScanReports = new Pather("stig-scan-reports");
  stigScanReport = new Pather("/:reportId", this.stigScanReports);
  stigScanReportDownload = new Pather("/downloads", this.stigScanReport);
  stigScanReportExport = new Pather("/export", this.stigScanReport);
  stigScanExport = new Pather("/export", this.stigScan);
  stigScanReportChecklist = new Pather("/checklists", this.stigScanReport);
  stigScanFindings = new Pather("/findings", this.stigScan);
  // SCAP
  stigScapScans = new Pather("/stig-scap");
  stigScapScanDelete = new Pather("/delete", this.stigScapScans);
  stigScapScan = new Pather("/:scanId", this.stigScapScans);
  stigScapScanReport = new Pather("/report", this.stigScapScan);
  stigScapExport = new Pather("/export", this.stigScapScans);
  stigScapScanExport = new Pather("/export", this.stigScapScan);
  stigScapChecklists = new Pather("/checklists", this.stigScapScans);
  // SCAP
  stigEvalScans = new Pather("/stig-eval");
  stigEvalScanDelete = new Pather("/delete", this.stigEvalScans);
  stigEvalScan = new Pather("/:scanId", this.stigEvalScans);
  stigEvalScanReport = new Pather("/report", this.stigEvalScan);
  stigEvalExport = new Pather("/export", this.stigEvalScans);
  stigEvalScanExport = new Pather("/export", this.stigEvalScan);
  stigEvalChecklists = new Pather("/checklists", this.stigEvalScans);

  /**
   *                       ACAS
   */
  // Comments
  acasComments = new Pather("/acas-comments");
  acasComment = new Pather("/:commentId", this.stigComments);
  // Plugins
  acasPlugins = new Pather("/acas-plugins");
  acasPluginsLatest = new Pather("/latest", this.acasPlugins);
  acasPlugin = new Pather("/:pluginId", this.acasPlugins);
  // Policies
  acasPolicies = new Pather("acas/policies");
  acasPolicy = new Pather("/:policyId", this.acasPolicies);
  // Scans
  acasScans = new Pather("acas/scans");
  acasScan = new Pather("/:scanId", this.acasScans);
  acasScanFindings = new Pather("/findings", this.acasScan);
  acasScanExport = new Pather("/export", this.acasScan);
  // Results
  acasReports = new Pather("acas/reports");
  acasReport = new Pather("/:reportId", this.acasReports);
  acasReportFindings = new Pather("/findings", this.acasReport);
  acasReportFinding = new Pather("/:pluginId", this.acasReportFindings);
  acasReportExport = new Pather("/export", this.acasReport);

  // CVEs
  cves = new Pather("/cve");
  cveLatest = new Pather("/latest", this.cves);
  cve = new Pather("/:cveId", this.cves);

  // IAVs
  iav = new Pather("/iav");
  iavLatest = new Pather("/latest", this.iav);

  // #################################################
  // ORGANIZATIONS
  // #################################################
  // Organizations
  organizations = new Pather("/organizations");
  organization = new Pather("/:organizationId", this.organizations);
  organizationToken = new Pather("/token", this.organization);
  organizationCert = new Pather("/cert", this.organization);
  organizationsRegister = new Pather("/register", this.organizations);
  organizationsDashboards = new Pather("/dashboards", this.organization);

  // widgets = new Pather('/widgets');

  // // Endpoints
  // widgetEndpointsActive = new Pather('/endpoints/active', this.widgets);
  // widgetEndpointsRecent = new Pather('/endpoints/recent', this.widgets);
  // widgetEndpointsByOs = new Pather('/endpoints/os', this.widgets);
  // widgetEndpointsStale = new Pather('/endpoints/stale', this.widgets);

  // // Teams
  // widgetTeamCount = new Pather('/teams/count', this.widgets);

  // // Group
  // widgetGroupCount = new Pather('/groups/count', this.widgets);

  // // Document
  // widgetDocumentCount = new Pather('/documents/count', this.widgets);

  // // Network
  // widgetNetworkAddressesActive = new Pather('/network/addresses', this.widgets);
  // widgetNetworkDomains = new Pather('/network/domains', this.widgets);
  // widgetNetworkEnvironments = new Pather('/network/environments', this.widgets);

  // // Users
  // widgetUsersActive = new Pather('/users/active', this.widgets);

  // // STIG
  // widgetStigBenchmarksTopFailed = new Pather('/stig/benchmarks/top-failed', this.widgets);

  // widgetStigChecklists = new Pather('/stig/checklists/active', this.widgets);

  // widgetStigScansRecent = new Pather('/stig/scans/recent', this.widgets);
  // widgetStigScansError = new Pather('/stig/scans/error', this.widgets);
  // widgetStigScansMissing = new Pather('/stig/scans/missing', this.widgets);
  // widgetStigScansInterval = new Pather('/stig/scans/interval', this.widgets);
  // widgetStigScansByOs = new Pather('/stig/scans/os', this.widgets);
  // widgetStigScansNonCompliantEndpoints = new Pather('/stig/scans/non-compliant-endpoints', this.widgets);
  // widgetStigScansNonCompliantEnvironments = new Pather('/stig/scans/non-compliant-environments', this.widgets);

  // widgetStigScoreInterval = new Pather('/stig/scores/interval', this.widgets);
  // widgetStigScoreScap = new Pather('/stig/scores/scap', this.widgets);
  // widgetStigScoreTotal = new Pather('/stig/scores/total', this.widgets);

  // widgetStigResultsTopFailed = new Pather('/stig/results/top-failed', this.widgets);
  // widgetStigResultsSeverity = new Pather('/stig/results/severity', this.widgets);
  // widgetStigResultsOpen = new Pather('/stig/results/open', this.widgets);

  // widgetStigDocumentationCount = new Pather('/stig/documentation/count', this.widgets);
  // widgetStigDocumentationExpiring = new Pather('/stig/documentation/expiring', this.widgets);
}
