import Pather from "services/Pather.service";

export class ClientRoutes {
  notifications = new Pather({ route: "/notifications", isPrivate: true });
  notification = new Pather({
    route: "/:notificationId",
    isPrivate: true,
    parent: this.notifications,
  });

  dashboards = new Pather({ route: "/dashboards", isPrivate: true });
  dashboardStig = new Pather({ route: "/stig", parent: this.dashboards });
  dashboardScap = new Pather({ route: "/scap", parent: this.dashboards });
  dashboardEval = new Pather({ route: "/eval", parent: this.dashboards });
  dashboardAcas = new Pather({ route: "/acas", parent: this.dashboards });

  faq = new Pather({ route: "/faq", isPrivate: true });

  endpoints = new Pather({ route: "/endpoints", isPrivate: true });
  endpoint = new Pather({
    route: "/:endpointId",
    isPrivate: true,
    parent: this.endpoints,
  });
  endpointsCreate = new Pather({
    route: "/create",
    isPrivate: true,
    parent: this.endpoints,
  });

  documents = new Pather({ route: "/documents", isPrivate: true });
  documentFolder = new Pather({ route: "/:folderId", parent: this.documents });
  documentTemplates = new Pather({
    route: "/templates",
    parent: this.documents,
  });
  documentTemplateFolder = new Pather({
    route: "/:folder",
    parent: this.documentTemplates,
  });
  documentTypes = new Pather({ route: "/types", parent: this.documents });
  documentTypesCreate = new Pather({
    route: "/create",
    parent: this.documentTypes,
  });

  reports = new Pather({ route: "/reports", isPrivate: true });

  // COMPLIANCE
  compliance = new Pather({ route: "/compliance", isPrivate: true });

  // -- Audits
  audits = new Pather({ route: "/audits", parent: this.compliance });
  ccriAudits = new Pather({ route: "/ccri", parent: this.audits });
  ccriAudit = new Pather({ route: "/:version", parent: this.ccriAudits });

  // -- Remediation
  remediations = new Pather({ route: "/remediation", isPrivate: true });
  remediationCreate = new Pather({
    route: "/create",
    parent: this.remediations,
  });
  remediation = new Pather({
    route: "/:remediationId",
    parent: this.remediations,
  });
  remediationList = new Pather({ route: "/list", parent: this.remediation });
  remediationWorklog = new Pather({
    route: "/work-log",
    parent: this.remediation,
  });
  remediationsScripts = new Pather({
    route: "/scripts",
    parent: this.remediations,
  });

  // -- Documentation
  complianceDocuments = new Pather({
    route: "/compliance",
    parent: this.documents,
  });
  complianceDocumentCreate = new Pather({
    route: "/create",
    parent: this.complianceDocuments,
  });
  complianceDocument = new Pather({
    route: "/:documentationId",
    parent: this.complianceDocuments,
  });

  // -- STIG
  stig = new Pather({ route: "/stig", parent: this.compliance });

  stigChecklists = new Pather({ route: "/checklists", parent: this.stig });
  stigChecklistCreate = new Pather({
    route: "/create",
    parent: this.stigChecklists,
  });
  stigChecklist = new Pather({
    route: "/:checklistId",
    parent: this.stigChecklists,
  });

  stigComments = new Pather({ route: "/comments", parent: this.stig });
  stigCommentCreate = new Pather({
    route: "/create",
    parent: this.stigComments,
  });
  stigComment = new Pather({ route: "/:commentId", parent: this.stigComments });

  stigBenchmarks = new Pather({ route: "/benchmarks", parent: this.stig });
  stigBenchmark = new Pather({
    route: "/:benchmarkId",
    parent: this.stigBenchmarks,
  });

  stigDocuments = new Pather({ route: "/documentation", parent: this.stig });
  stigDocumentCreate = new Pather({
    route: "/create",
    parent: this.stigDocuments,
  });
  stigDocument = new Pather({
    route: "/:documentationId",
    parent: this.stigDocuments,
  });

  stigScans = new Pather({ route: "/scans", parent: this.stig });
  stigManualScan = new Pather({
    route: "/:scanId/manual",
    parent: this.stigScans,
  });
  stigScan = new Pather({ route: "/:policyId", parent: this.stigScans });
  // stigScanEndpoints = new Pather({ route: '/endpoints', parent: this.stigScan });
  stigScanFindings = new Pather({ route: "/findings", parent: this.stigScan });
  stigScanHistory = new Pather({ route: "/history", parent: this.stigScan });
  stigScanNew = new Pather({ route: "/new", parent: this.stigScans });
  stigScanConfig = new Pather({ route: "/config", parent: this.stigScan });
  stigScanFolders = new Pather({ route: "/folders", parent: this.stigScans });
  stigScanFolder = new Pather({
    route: "/:folderName",
    parent: this.stigScanFolders,
  });
  stigScanReports = new Pather({ route: "/reports", parent: this.stigScan });
  stigScanReport = new Pather({
    route: "/:reportId",
    parent: this.stigScanReports,
  });
  stigScanReportResult = new Pather({
    route: "/:resultId",
    parent: this.stigScanReport,
  });

  stigScaps = new Pather({ route: "/scap", parent: this.stig });
  stigScap = new Pather({ route: "/:scanId", parent: this.stigScaps });

  stigEvals = new Pather({ route: "/evaluate", parent: this.stig });
  stigEval = new Pather({ route: "/:id", parent: this.stigEvals });

  // -- ACAS
  acas = new Pather({ route: "/acas", parent: this.compliance });
  acasScans = new Pather({ route: "/scans", parent: this.acas });
  acasScan = new Pather({ route: "/:policyId", parent: this.acasScans });
  acasScanVulns = new Pather({
    route: "/vulnerabilities",
    parent: this.acasScan,
  });
  acasScanVuln = new Pather({
    route: "/:pluginId",
    parent: this.acasScanVulns,
  });
  acasScanHistory = new Pather({ route: "/history", parent: this.acasScan });
  acasScanReports = new Pather({ route: "/reports", parent: this.acasScan });
  acasScanReport = new Pather({
    route: "/:reportId",
    parent: this.acasScanReports,
  });
  acasScanReportVuln = new Pather(
    "/vulnerabilities/:pluginId",
    this.acasScanReport
  );

  acasPlugins = new Pather({ route: "/plugins", parent: this.acas });
  acasPlugin = new Pather({ route: "/:pluginId", parent: this.acasPlugins });

  cves = new Pather({ route: "/cves", parent: this.compliance });
  cve = new Pather({ route: "/:cveId", parent: this.cves });

  acasComments = new Pather({ route: "/comments", parent: this.acas });
  acasCommentCreate = new Pather({
    route: "/create",
    parent: this.acasComments,
  });
  acasComment = new Pather({ route: "/:commentId", parent: this.acasComments });

  acasDocuments = new Pather({ route: "/documentation", parent: this.acas });
  acasDocumentCreate = new Pather({
    route: "/create",
    parent: this.acasDocuments,
  });
  acasDocument = new Pather({
    route: "/:documentationId",
    parent: this.acasDocuments,
  });

  // Auth/Profile/Settings
  profile = new Pather({ route: "/profile", isPrivate: true });
  settings = new Pather({ route: "/settings", isPrivate: true });
  login = new Pather("/login");
  logout = new Pather({ route: "/logout", isPrivate: true });

  // GROUPS
  groups = new Pather({ route: "/groups", isPrivate: true });
  group = new Pather({ route: "/:groupId", parent: this.groups });
  groupsCreate = new Pather({ route: "/create", parent: this.groups });

  // TEAMS
  teams = new Pather({ route: "/teams", isPrivate: true });
  teamsCreate = new Pather({ route: "/create", parent: this.teams });
  team = new Pather({ route: "/:teamId", parent: this.teams });
  teamEndpoints = new Pather({ route: "/endpoints", parent: this.team });
  teamRemediation = new Pather({ route: "/remediation", parent: this.team });
  teamStig = new Pather({ route: "/stig", parent: this.team });
  teamAcas = new Pather({ route: "/acas", parent: this.team });

  // ADMIN
  admin = new Pather({ route: "/admin", isPrivate: true, requiresAdmin: true });

  // ADMIN DATA
  adminData = new Pather({ route: "/data", parent: this.admin });

  // ADMIN ABOUT
  adminOverview = new Pather({ route: "/overview", parent: this.admin });
  adminLicense = new Pather({ route: "/license", parent: this.adminOverview });

  // Updates page
  adminAbout = new Pather({ route: "/about", parent: this.admin });

  // ADMIN ORGANIZATIONS
  adminOrganizations = new Pather({
    route: "/organizations",
    parent: this.admin,
  });
  adminOrganization = new Pather({
    route: "/:organizationId",
    parent: this.adminOrganizations,
  });
  adminOrganizationsCreate = new Pather({
    route: "/create",
    parent: this.adminOrganizations,
  });

  // ADMIN MEMBERS
  adminMembers = new Pather({ route: "/members", parent: this.admin });
  adminMember = new Pather({ route: "/:memberId", parent: this.adminMembers });
  adminMemberAdd = new Pather({ route: "/add", parent: this.adminMembers });

  // API KEYS
  adminApiKeys = new Pather({ route: "/api-keys", parent: this.admin });
  adminApiKey = new Pather({ route: "/:id", parent: this.adminApiKeys });
  adminApiKeyCreate = new Pather({
    route: "/create",
    parent: this.adminApiKeys,
  });

  //LOGS
  adminLogs = new Pather({ route: "/logs", parent: this.admin });

  // ADMIN SETTINGS
  adminSettings = new Pather({ route: "/settings", parent: this.admin });

  // ADMIN GENERAL SETTINGS
  adminSettingsAdvanced = new Pather({
    route: "/advanced",
    parent: this.adminSettings,
  });
  adminSettingsAdvancedUi = new Pather({
    route: "/ui",
    parent: this.adminSettingsAdvanced,
  });
  adminSettingsAdvancedStig = new Pather({
    route: "/stig",
    parent: this.adminSettingsAdvanced,
  });
  adminSettingsAdvancedScap = new Pather({
    route: "/scap",
    parent: this.adminSettingsAdvanced,
  });
  adminSettingsAdvancedEval = new Pather({
    route: "/eval",
    parent: this.adminSettingsAdvanced,
  });
  adminSettingsAdvancedAcas = new Pather({
    route: "/acas",
    parent: this.adminSettingsAdvanced,
  });

  // ADMIN PROXY
  adminSettingsProxy = new Pather({
    route: "/proxy-server",
    parent: this.adminSettings,
  });

  // ADMIN SMTP
  adminSettingsSmtp = new Pather({
    route: "/smtp-server",
    parent: this.adminSettings,
  });

  // ADMIN SESSIONS
  adminSettingsSessions = new Pather({
    route: "/sessions",
    parent: this.adminSettings,
  });

  // adminSettingsGeneral = new Pather({ route: '/general', parent: this.adminSettings });
  // adminSettingsStig = new Pather({ route: '/stig', parent: this.adminSettings });
  // adminSettingsScap = new Pather({ route: '/scap', parent: this.adminSettings });
  // adminSettingsAcas = new Pather({ route: '/acas', parent: this.adminSettings });
  // adminSettingsNotifications = new Pather({ route: '/notifications', parent: this.adminSettings });

  // ADMIN

  // ORGANIZATION
  organization = new Pather({ route: "/organizations", isPrivate: true });

  // DOMAINS
  organizationDomains = new Pather({
    route: "/domains",
    parent: this.organization,
  });
  organizationDomainsCreate = new Pather({
    route: "/create",
    parent: this.organizationDomains,
  });
  organizationDomainsEdit = new Pather({
    route: "/:domainId/edit",
    parent: this.organizationDomains,
  });
  // DOMAINS

  // ENVIRONMENTS
  organizationEnvironments = new Pather({
    route: "/environments",
    parent: this.organization,
  });
  organizationEnvironmentsCreate = new Pather({
    route: "/create",
    parent: this.organizationEnvironments,
  });
  organizationEnvironmentsEdit = new Pather({
    route: "/:environmentId/edit",
    parent: this.organizationEnvironments,
  });
  // ENVIRONMENTS

  // KEYS
  organizationAgentKeys = new Pather({
    route: "/keys",
    parent: this.organization,
  });
  organizationAgentKey = new Pather({
    route: "/:keyId",
    parent: this.organizationAgentKeys,
  });
  organizationAgentKeysCreate = new Pather({
    route: "/create",
    parent: this.organizationAgentKeys,
  });
  // KEYS

  // ORGANIZATION

  // register = new Pather({ route: 'register', parent: this.base });
  // registered = new Pather({ route: 'registered', parent: this.base });
  // login = new Pather({ route: 'login', parent: this.base });
  // logout =  new Pather({ route: 'logout', parent: this.base });
  // forgot =  new Pather({ route: 'forgot', parent: this.base });
  // reset = new Pather({ route: 'reset/:token', parent: this.base });
  // confirm = new Pather({ route: 'confirm/:token', parent: this.base });
  // requestConfirm = new Pather({ route: 'request-confirm', parent: this.base });
}
